import _defineProperty from "F:\\\u80F6\u5DF1\u4EBA\\jjr_store_reservation\\node_modules\\@babel\\runtime\\helpers\\esm\\defineProperty.js";
import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import "vant/es/form/style";
import _Form from "vant/es/form";
import "vant/es/field/style";
import _Field from "vant/es/field";
import "vant/es/tag/style";
import _Tag from "vant/es/tag";
import "vant/es/card/style";
import _Card from "vant/es/card";
import "vant/es/button/style";
import _Button from "vant/es/button";

var _components;

import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getAgreement } from '@/utils/agreement';
export default {
  name: 'Home',
  components: (_components = {}, _defineProperty(_components, _Button.name, _Button), _defineProperty(_components, _Card.name, _Card), _defineProperty(_components, _Tag.name, _Tag), _defineProperty(_components, _Field.name, _Field), _defineProperty(_components, _Form.name, _Form), _defineProperty(_components, _Toast.name, _Toast), _components),
  data: function data() {
    return {
      text: getAgreement()
    };
  },
  created: function created() {},
  methods: {
    // 返回
    onClickLeft: function onClickLeft() {
      this.$router.go(-1);
    }
  }
};